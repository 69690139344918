import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Relatórios',
    icon: 'mdi mdi-view-dashboard',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/relatorios/lista',
        title: 'Lista de Relatórios',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Cadastros',
    icon: 'mdi mdi-code-equal',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/forms/listaempresas',
        title: 'Empresas',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/listaarmazens',
        title: 'Armazéns',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/listaenderecos',
        title: 'Endereços',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/listaalocacao',
        title: 'Alocações',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Integrações',
    icon: 'mdi mdi-code-equal',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/forms/clientes',
        title: 'Clientes',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/produtos',
        title: 'Produtos',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Recebimentos',
    icon: 'mdi mdi-code-equal',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/recebimento/lista',
        title: 'Pedidos de Compras',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Separação',
    icon: 'mdi mdi-code-equal',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: "/separacao-pedido-venda/lista",
        title: "Pedidos de Vendas",
        icon: "mdi mdi-attachment",
        class: "",
        extralink: false,
        submenu: [],
      },
      // {
      //   path: "/forms/kanban-pedidos",
      //   title: "Separação por Onda",
      //   icon: "mdi mdi-attachment",
      //   class: "",
      //   extralink: false,
      //   submenu: [],
      // },
    ]
  },
  // {
  //   path: '',
  //   title: 'Embalagens',
  //   icon: 'mdi mdi-code-equal',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/forms/listaembalagens',
  //       title: 'Lista de Embalagens',
  //       icon: 'mdi mdi-attachment',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //   ]
  // },

  {
    path: '',
    title: 'Estoque',
    icon: 'mdi mdi-code-equal',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/estoque/lista',
        title: 'Listagem',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/estoque/mapa',
        title: 'Mapa',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/estoque/historico',
        title: 'Histórico',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Movimentações',
    icon: 'mdi mdi-code-equal',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/guia-movimentos/lista-entradas',
        title: 'Entradas',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/guia-movimentos/lista-saidas',
        title: 'Saídas',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/guia-movimentos/lista-transferencias',
        title: 'Transferências',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  // {
  //   path: '',
  //   title: 'Embalagens',
  //   icon: 'mdi mdi-code-equal',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/forms/listaembalagens',
  //       title: 'Lista de Embalagens',
  //       icon: 'mdi mdi-attachment',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //   ]
  // },

  {
    path: '',
    title: 'Romaneios',
    icon: 'mdi mdi-code-equal',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/forms/rotas',
        title: 'Rotas e Trajetos',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/listaordemcarga',
        title: 'Ordens de Carga',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },

  {
    path: '',
    title: 'Transportadoras',
    icon: 'mdi mdi-code-equal',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/transportadoras/lista',
        title: 'Transportadoras',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Inventário',
    icon: 'mdi mdi-code-equal',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/inventario/lista',
        title: 'Listagem',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },

  {
    path: 'acessos',
    title: 'Acessos',
    icon: 'mdi mdi-code-equal',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/acessos/listar-usuarios',
        title: 'Usuários',
        icon: 'mdi mdi-select-inverse',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/acessos/listar-grupos',
        title: 'Grupos',
        icon: 'mdi mdi-select-inverse',
        class: '',
        extralink: false,
        submenu: []
      },
    ]
  },

  /*{
    path: '',
    title: 'Sair',
    icon: 'mdi mdi-code-equal',
    class: '',
    extralink: false,
    submenu: []
  },*/
];
